<template>
  <v-container>
    <v-main class="pt-15 pb-15">
      <loading-page v-if="loading"/>
      <div>
        <v-component :is="currentTabComponent"/>
      </div>
      <action :currentTab="page"/>
    </v-main>
  </v-container>
</template>
<script>
import loadingPage from '@/components/tools/loadingPage.vue'
import action from './components/navbar/actionBar'
import megaApp from './megaAppPage'
export default {
  props:['app'],
  components: {
    loadingPage,
    action,
    mega: megaApp,
    rules: () => import ( '@/views/shop/home/rules.vue' ),
    aboutUs: () => import ( '@/views/shop/home/aboutUs.vue' ),
    contactUs: () => import ( '@/views/shop/home/contactUs.vue' ),
    my_location: () => import ( '@/views/Address/myLocations.vue' ),
    my_orders: () => import ( '@/views/Factor/myOrders.vue' ),
    wallet: () => import ( '@/views/Wallet/wallet.vue' ),
    inviteFriends: () => import ( '@/views/shop/home/inviteFriends')
  },
  data: () => ({
    loading: false,
    page: 'mega'
  }),
  computed: {
    currentTabComponent: function() {
      if (!this.$props.app) {
        const url = 'mega'
        return url;
      }
      return this.$props.app;
    }
  },
  methods: {
    tab (tab) {
      this.page = tab
    }
  }
}
</script>